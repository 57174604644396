<template>
  <div class="myAboutUs">
    <div class="go-back">
      <div @click="onClickLeft">
        <van-icon name="arrow-left" class="icon-left"/>
        <span>关于我们</span>
      </div>
    </div>
    <div class="content">
      <div class="title"></div>
      <div class="desc">本平台为分次支付账单分期服务平台，本平台作为一款费用支付工具，仅提供技术系统服务支持，商家提供商品/服务的内容及售后，如遇商品及售后问题，请您与商家协商处理。</div>
      <!-- <div v-if='bankCardList.length == 0' style="display:flex; flex-direction:column; align-items:center;">
        <van-empty description="暂未绑卡" style="padding-bottom:0;" />
        <div class="bind-button" @click="bindCard">立即绑定</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'myAboutUs',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    onClickLeft(){
      this.$router.push('/my')
    },
  },
};
</script>

<style lang="scss" scoped>
.myAboutUs{
  height: 100%;   
  width: 100%;
  background: #f5f5f5;
  .go-back{
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #E7E7E7;
    border-bottom: 1px solid #E7E7E7;
    height: 100px;
  }
  .content{
    padding: 30px 40px;
    .desc{
      margin-top: 20px;
      font-size: 26px;
      line-height: 60px;
      text-align: justify;
    }
  }
}
</style>